import React, { useEffect, useState, useCallback } from "react";
import { useData } from "../../providers/DataProvider";
import { BuilderInfo, JobDetailsProps } from "../../providers/types";
import AxiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";
import {
  TrashIcon,
  PlusIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import { Modal, Box, Tooltip } from "@mui/material";

interface bonusHistory {
  bonusHistoryId: number;
  comments: string;
  builders: any[];
  bonusId: number;
  jobId: number;
  salesPrice: number;
  closingBonusAmount: number;
  stage5BonusAmount: number;
  unusedBonusAmount: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
}

interface BuilderSplitModalProps {
  jobId: number;
}

const BuilderSplitModal: React.FC<BuilderSplitModalProps> = ({ jobId }) => {
  const { role } = useData();
  const { accounts } = useMsal();
  const [open, setOpen] = useState<boolean>(false);
  const [builderSplits, setBuilderSplits] = useState<BuilderInfo[]>([]);
  const [job, setJob] = useState<JobDetailsProps | undefined>(undefined);
  const [allBuilders, setAllBuilders] = useState<
    {
      builderEmail: string;
      builderName: string;
      builderNumber: number;
    }[]
  >([]);
  const [comments, setComments] = useState<string>("");
  const [unusedBonusAmount, setUnusedBonusAmount] = useState<number>(0);
  const [isReadyOnly, setIsReadyOnly] = useState<boolean>(true);
  const [isHistoryExpanded, setIsHistoryExpanded] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setBuilderSplits([]);
  };

  const handleAddBuilder = useCallback(() => {
    setBuilderSplits((prev) => [
      ...prev,
      {
        builderId: 0,
        jobId,
        builderName: "",
        builderNumber: 0,
        builderEmail: "",
        split: 0,
        splitAmount: 0,
        createdOn: "",
        createdBy: "",
        updatedOn: "",
        updatedBy: "",
        isActive: false,
      },
    ]);
  }, [jobId]);

  const handleBuilderChange = useCallback(
    (index: number, builderNumber: number) => {
      setBuilderSplits((prev) =>
        prev.map((builderSplit, idx) => {
          if (idx === index) {
            const selectedBuilder = allBuilders.find(
              (builder) => builder.builderNumber === builderNumber
            );
            return selectedBuilder
              ? {
                  ...builderSplit,
                  builderId: selectedBuilder.builderNumber,
                  builderName: selectedBuilder.builderName,
                  builderEmail: selectedBuilder.builderEmail,
                  builderNumber: selectedBuilder.builderNumber,
                }
              : builderSplit;
          }
          return builderSplit;
        })
      );
    },
    [allBuilders]
  );

  const handleSplitChange = useCallback(
    (index: number, split: number) => {
      const closingBonusAmount =
        (job?.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      setBuilderSplits((prev) =>
        prev.map((builderSplit, idx) =>
          idx === index
            ? {
                ...builderSplit,
                split: Math.max(0, split),
                splitAmount: parseFloat(
                  (closingBonusAmount * (split / 100)).toFixed(2)
                ),
              }
            : builderSplit
        )
      );
    },
    [job, unusedBonusAmount]
  );

  const handleBonusAmountChange = useCallback(
    (index: number, splitAmount: number) => {
      const closingBonusAmount =
        (job?.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      const totalSplitAmount = builderSplits.reduce(
        (total, builderSplit, idx) => {
          return (
            total + (idx === index ? splitAmount : builderSplit.splitAmount)
          );
        },
        0
      );

      if (totalSplitAmount > closingBonusAmount) {
        alert("Total bonus amount cannot exceed the closing bonus amount.");
        return;
      }

      setBuilderSplits((prev) =>
        prev.map((builderSplit, idx) =>
          idx === index
            ? { ...builderSplit, splitAmount: Math.max(0, splitAmount) }
            : builderSplit
        )
      );
    },
    [builderSplits, job, unusedBonusAmount]
  );

  const handleUnusedBonusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Math.max(0, parseFloat(e.target.value) || 0);
      if (job && value > job.bonusDetail.bonus.closingBonusAmount) {
        alert(
          "Unused bonus amount cannot be greater than the closing bonus amount."
        );
        setUnusedBonusAmount(job.bonusDetail.bonus.closingBonusAmount);
      } else {
        setUnusedBonusAmount(value);
      }
    },
    [job]
  );

  const handleContinue = useCallback(() => {
    if (!builderSplits.length) {
      alert("Please select a builder");
      return;
    }
    if (builderSplits.some((builderSplit) => !builderSplit.builderName)) {
      alert("Missing builder name");
      return;
    }
    if (builderSplits.some((builderSplit) => builderSplit.split === 0)) {
      alert("All builders must have a split greater than 0%.");
      return;
    }
    if (builderSplits.reduce((acc, curr) => acc + curr.split, 0) !== 100) {
      alert("Total split must equal 100%.");
      return;
    }
    if (
      builderSplits.reduce(
        (total, builderSplit) => total + builderSplit.splitAmount,
        0
      ) +
        unusedBonusAmount >
      job?.bonusDetail.bonus.closingBonusAmount
    ) {
      alert(
        "Total bonus amount including unused bonus cannot exceed the closing bonus amount."
      );
      return;
    }
    if (!comments.trim()) {
      alert("Comments are required");
      return;
    }

    AxiosInstance.post("/api/cba/builders/bulk", {
      JobId: jobId,
      UnusedBonusAmount: unusedBonusAmount,
      UpdatedBy: accounts[0].username,
      Comments: comments,
      BulkUpdateBuildersRequests: builderSplits.map((builder) => ({
        JobId: jobId,
        BuilderName: builder.builderName,
        BuilderEmail: builder.builderEmail,
        BuilderNumber: builder.builderNumber,
        Split: builder.split,
        SplitAmount: builder.splitAmount,
        CreatedBy: accounts[0].username,
      })),
    })
      .then(() => {
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to save builder splits:", error);
        alert("An error occurred while saving. Please try again.");
      });
  }, [
    accounts,
    builderSplits,
    comments,
    handleClose,
    job,
    jobId,
    unusedBonusAmount,
  ]);

  const loadAllBuilders = useCallback(() => {
    AxiosInstance.post(`/api/cba/builders/search`)
      .then((response) => setAllBuilders(response.data))
      .catch((error) =>
        console.error("Error fetching job builder details:", error)
      );
  }, []);

  const loadJob = useCallback(() => {
    AxiosInstance.get(`/api/cba/job-details/${jobId}`, {
      headers: {
        roleType: role,
      },
    })
      .then((response) => {
        setJob(response.data);
        setUnusedBonusAmount(response.data.bonusDetail.bonus.unusedBonusAmount);
        setIsReadyOnly(response.data.bonusDetail.isReadyOnly);
        if (!response.data.bonusDetail.isReadyOnly) {
          loadAllBuilders();
        }
        if (response.data.isDefaultSplitAmount) {
          setBuilderSplits(
            response.data.builders.map(
              (builder: { split: any; splitAmount: number }) => {
                const closingNumberAmount =
                  response.data.BonusDetail.bonus.closingBonusAmount;
                const unusedBonusAmount =
                  response.data.BonusDetail.bonus.unusedBonusAmount;
                builder.splitAmount =
                  (closingNumberAmount - unusedBonusAmount) *
                  builder.split *
                  0.01;
                return builder;
              }
            )
          );
        } else {
          setBuilderSplits(response.data.builders);
        }
      })
      .catch((error) => console.error("Error fetching job details:", error));
  }, [jobId, loadAllBuilders, role]);

  useEffect(() => {
    if (open) {
      loadJob();
    }
  }, [open, loadJob]);

  useEffect(() => {
    if (job) {
      const closingBonusAmount =
        (job.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      setBuilderSplits((prev) =>
        prev.map((builderSplit) => ({
          ...builderSplit,
          splitAmount: parseFloat(
            (closingBonusAmount * (builderSplit.split / 100)).toFixed(2)
          ),
        }))
      );
    }
  }, [job, unusedBonusAmount]);

  useEffect(() => {
    if (
      builderSplits.length > 0 &&
      builderSplits.every((builderSplit) => builderSplit.splitAmount === 0)
    ) {
      const closingBonusAmount =
        (job?.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      setBuilderSplits((prev) =>
        prev.map((builderSplit) => ({
          ...builderSplit,
          splitAmount: parseFloat(
            (closingBonusAmount * (builderSplit.split / 100)).toFixed(2)
          ),
        }))
      );
    }
  }, [builderSplits, job, unusedBonusAmount]);

  return (
    <>
      <Tooltip title="Builder Split">
        <button
          onClick={handleOpen}
          className="p-2 text-gray-400 hover:text-gray-500"
          title="Builder Split"
        >
          <CurrencyDollarIcon className="h-5 w-5 text-primary-500" />
        </button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "1024px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
            borderRadius: "10px",
          }}
        >
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                Builder Split
              </h3>
              <div className="mt-2">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Builder
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Split (%)
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Bonus Amount
                        </th>
                        {!isReadyOnly && (
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {builderSplits.map((builderSplit, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {isReadyOnly ? (
                              <div>
                                <div className="text-sm font-medium text-gray-900">
                                  {builderSplit.builderName}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {builderSplit.builderEmail}
                                </div>
                              </div>
                            ) : (
                              <select
                                value={builderSplit.builderNumber}
                                onChange={(e) =>
                                  handleBuilderChange(
                                    index,
                                    Number(e.target.value)
                                  )
                                }
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                              >
                                <option value="">Select Builder</option>
                                {builderSplit.builderName && 
                                  !allBuilders.some(b => b.builderNumber === builderSplit.builderNumber) && (
                                    <option value={builderSplit.builderNumber}>
                                      {`${builderSplit.builderName} (${builderSplit.builderEmail})`}
                                    </option>
                                )}
                                {allBuilders.map((builder) => (
                                  <option
                                    key={builder.builderEmail}
                                    value={builder.builderNumber}
                                  >
                                    {`${builder.builderName} (${builder.builderEmail})`}
                                  </option>
                                ))}
                              </select>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <input
                              type="number"
                              value={builderSplit.split}
                              onChange={(e) =>
                                handleSplitChange(
                                  index,
                                  Math.max(0, parseInt(e.target.value, 10))
                                )
                              }
                              min="0"
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                              readOnly={isReadyOnly}
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <input
                              type="number"
                              value={builderSplit.splitAmount.toFixed(2)}
                              onChange={(e) =>
                                handleBonusAmountChange(
                                  index,
                                  parseFloat(e.target.value)
                                )
                              }
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                              readOnly={isReadyOnly}
                            />
                          </td>
                          {!isReadyOnly && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              <button
                                onClick={() =>
                                  setBuilderSplits((prev) =>
                                    prev.filter((_, idx) => idx !== index)
                                  )
                                }
                                className="text-red-600 hover:text-red-900"
                              >
                                <TrashIcon className="h-5 w-5" />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {builderSplits.length < allBuilders.length && !isReadyOnly && (
                  <button
                    onClick={handleAddBuilder}
                    className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
                    Add Builder
                  </button>
                )}
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1">
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium text-gray-700">
                        Closing Bonus
                      </label>
                      <span className="text-sm text-gray-500">
                        {job?.bonusDetail.bonus.closingBonusAmount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium text-gray-700">
                        Stage 5 Bonus
                      </label>
                      <span className="text-sm text-gray-500">
                        {job?.bonusDetail.bonus.stage5BonusAmount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                      </span>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Unused Bonus
                      </label>
                      <input
                        type="number"
                        value={unusedBonusAmount}
                        onChange={handleUnusedBonusChange}
                        min="0"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                        readOnly={isReadyOnly}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="comments"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Comments
                  </label>
                  <textarea
                    id="comments"
                    rows={6}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    readOnly={isReadyOnly}
                  ></textarea>
                </div>
              </div>
              <div className="border-t border-gray-200 pt-4">
                <button
                  type="button"
                  className="w-full flex justify-between items-center text-left text-gray-500 hover:text-gray-600"
                  onClick={() => setIsHistoryExpanded(!isHistoryExpanded)}
                >
                  <span className="font-medium">Split History</span>
                  <ChevronDownIcon
                    className={`h-5 w-5 transform ${
                      isHistoryExpanded ? "rotate-180" : ""
                    }`}
                  />
                </button>
                {isHistoryExpanded && (
                  <div className="mt-4 space-y-4">
                    {job?.bonusDetail?.bonusHistory
                      .sort(
                        (
                          a: { updatedOn: string | number | Date },
                          b: { updatedOn: string | number | Date }
                        ) =>
                          new Date(b.updatedOn).getTime() -
                          new Date(a.updatedOn).getTime()
                      )
                      .map((bonusDetail: bonusHistory) => (
                        <div
                          key={bonusDetail.bonusHistoryId}
                          className="bg-gray-100 p-4 rounded-lg"
                        >
                          <p className="text-sm text-gray-500">
                            Updated by {bonusDetail.updatedBy} on{" "}
                            {new Date(bonusDetail.updatedOn).toLocaleString()}
                          </p>
                          <p className="text-sm text-gray-500">
                            Closing Bonus $
                            {bonusDetail.closingBonusAmount.toLocaleString()}
                          </p>
                          <p className="text-sm text-gray-500">
                            Stage5 Bonus $
                            {bonusDetail.stage5BonusAmount.toLocaleString()}
                          </p>
                          <p className="text-sm text-gray-500">
                            Unused Bonus $
                            {bonusDetail.unusedBonusAmount.toLocaleString()}
                          </p>
                          <p className="text-sm text-gray-500 mt-2">
                            Comments: {bonusDetail.comments}
                          </p>
                          <table className="min-w-full divide-y divide-gray-200 mt-4">
                            <thead>
                              <tr>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Builder Name
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Builder Email
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Split
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Split Amount
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {bonusDetail.builders.map((builder, index) => (
                                <tr key={index}>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {builder.builderName}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {builder.builderEmail}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {builder.split}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    ${builder.splitAmount.toLocaleString()}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {builder.action}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={handleContinue}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              disabled={isReadyOnly}
            >
              Save
            </button>
            <button
              onClick={handleClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BuilderSplitModal;
