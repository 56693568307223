export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export type commentsProps = {
  action: string;
  comment: string;
  createdBy: string;
  createdOn: string;
  id: number;
  jobId: number;
  roleTypeId: number;
  fileAttachmentId: number;
  fileAttachmentName: string;
  fileAttachmentTypeName: string;
};

export type CompanyProps = {
  companyId: number;
  companyName: string;
  regionIds: string;
};

export type RegionProps = {
  regionId: number | "All"; // Allow 'All' as a special case identifier
  regionName: string;
};

export type CommunitiesProps = {
  company: string;
  companyId: number;
  jobCount: number;
  month: number;
  projectId: number;
  projectNumber: number;
  regionId: number;
  regionName: string;
  subDivision: string;
  year: number;
};

export type JobProps = {
  areaName: string;
  areaId: number;
  buyer: string;
  buyerCombined: string;
  companyId: string;
  jobAddress: string;
  jobId: number;
  jobNumber: string;
  jobTypeCode: string;
  month: number;
  planDescription: string;
  planElevationNumber: string;
  fileAttachmentTypes: fileAttachmentsTypesProps[];
  fileAttachments: fileAttachmentsProps[];
  fileAttachmentsByTypes: fileAttachmentsByTypesProps[];
  planNumber: string;
  profitAnalysisType: string;
  projectId: number;
  projectNumber: string;
  projectedCloseDate: string;
  regionId: number;
  salesPrice: number;
  soldDate: string;
  stageCode: string;
  stageDate: string;
  startDate: string;
  subdivision: string;
  year: string;
};

export type fileAttachmentsTypesProps = {
  name: string;
  fileAttachmentTypesId: number;
  isRequired: boolean;
};

export type fileAttachmentsByTypesProps = {
  fileAttachmentName: string;
  fileAttachmentTypeId: number;
  fileAttachments: fileAttachmentsProps[];
  isRequired: boolean;
};

export type fileAttachmentsProps = {
  attachedFile: string;
  createdBy: string;
  createdOn: string;
  fileAttachmentId: number;
  fileAttachmentTypeId: number;
  fileName: string;
  fileUri: string;
  isActive: boolean;
  jobId: number;
  updatedBy: string;
  updatedOn: string;
  fileBlob?: any;
  isMergedFile: boolean;
};

export type JobDetailsProps = {
  areaName: string;
  areadId: number;
  buyer: string;
  buyerCombined: string;
  builders: BuilderInfo[];
  companyId: string;
  jobAddress: string;
  jobId: number;
  jobNumber: string;
  jobTypeCode: string;
  month: number;
  planDescription: string;
  planElevationNumber: string;
  planNumber: string;
  profitAnalysisType: string;
  projectId: number;
  projectNumber: string;
  projectedCloseDate: string;
  regionId: number;
  reviewStatus: string;
  salesPrice: number;
  soldDate: string;
  stageCode: string;
  stageDate: string;
  startDate: string;
  subdivision: string;
  year: string;
  fileAttachmentTypes: fileAttachmentsTypesProps[];
  fileAttachments: fileAttachmentsProps[];
  fileAttachmentsByTypes: fileAttachmentsByTypesProps[];
  bonusDetail: any;
  reviewcomments: commentsProps[];
  payrollProcessedOn: string;
};

export type BuilderInfo = {
  builderId: number;
  jobId: number;
  builderName: string;
  builderNumber: number;
  builderEmail: string;
  split: number;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
  isActive: boolean;
  splitAmount: number;
};

export type BonusProps = {
  biM360UploadIssue: boolean;
  bonusId: number;
  comments: any[];
  createdBy: string;
  createdOn: string;
  jobId: number;
  opsReviewedOn: string;
  opsReviewer: string;
  pmReviewedOn: string;
  pmReviewer: string;
  statusTypeId: number;
  updatedBy: string;
  updatedOn: string;
  regionName: string;
  payrollPaidOn: string;
  payrollProcessedOn: string;
  subdivision: string; // Add this line
};

export type ProjectsProps = {
  companyId: number;
  companyName: string;
  projectId: number;
  projectNumber: number;
  subdivision: string;
  areaId: number;
  areaName: string;
  regionId: number;
  isPmSkipped: boolean;
  isSpmSkipped: boolean;
  skipReviews: {
    skipReviewId: number;
    projectId: number;
    reviewStepId: number;
  }[];
};
