import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import Layout from "./components/Layout";
import "./index.css";
import "./input.css";
import FileUploadSearch from "./pages/FileUploadSearch";
import FileUploadList from "./pages/FileUploadList";
import { Container, Box } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { SignInButton } from "./components/Buttons/SignInButton";
import { Route, Routes } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import UploadHome from "./pages/UploadHome";
import Home from "./pages/Home";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Review from "./pages/Review";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/index";
import { DataProvider } from "./providers/DataProvider";
import Admin from "./pages/Admin";
import Payroll from "./pages/Payroll";
import ReviewId from "./pages/ReviewId";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const AuthWrapper = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }

  return (
    <div className="App">
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <DataProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthWrapper>
                    <Home />
                  </AuthWrapper>
                }
              />
              <Route
                path="/admin"
                element={
                  <AuthWrapper>
                    <Admin />
                  </AuthWrapper>
                }
              />
              <Route
                path="/payroll"
                element={
                  <AuthWrapper>
                    <Payroll />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload"
                element={
                  <AuthWrapper>
                    <UploadHome />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload/create"
                element={
                  <AuthWrapper>
                    <FileUploadSearch />
                  </AuthWrapper>
                }
              />
              <Route
                path="/upload/create/:id"
                element={
                  <AuthWrapper>
                    <FileUploadList />
                  </AuthWrapper>
                }
              />
              <Route
                path="/review"
                element={
                  <AuthWrapper>
                    <Review />
                  </AuthWrapper>
                }
              />
              <Route
                path="/review/:id"
                element={
                  <AuthWrapper>
                    <ReviewId />
                  </AuthWrapper>
                }
              />
            </Routes>
          </Layout>
        </LocalizationProvider>
      </DataProvider>
    </ThemeProvider>
  );
}
