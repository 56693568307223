import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Theme,
  Paper,
  Grid,
  TextField,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Button,
  Tooltip,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Stack,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { useData } from "../../providers/DataProvider";
import {
  fileAttachmentsByTypesProps,
  fileAttachmentsProps,
  JobDetailsProps,
} from "../../providers/types";
import theme from "../../theme";
import axiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

interface Props {
  jobId: number;
}

const ReviewerViewModal = ({ jobId }: Props) => {
  const { loadBonuses, role } = useData();
  const { accounts } = useMsal();
  const [open, setOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [job, setJob] = useState<JobDetailsProps | undefined>(undefined);
  const [reviewDecision, setReviewDecision] = useState("");
  const [commentText, setCommentText] = useState("");
  const [fileCommentText, setFileCommentText] = useState("");
  const [fileStreamUrl, setFileStreamUrl] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [viewPending, setViewPending] = useState(true);
  const [historyComments, setHistoryComments] = useState<
    {
      action: string;
      comment: string;
      createdBy: string;
      createdOn: string;
      fileAttachmentId: number;
      fileAttachmentName: string;
      fileAttachmentTypeName: string;
      id: number;
      jobId: number;
      roleTypeId: number;
    }[]
  >([]);
  const [filecomments, setFileComments] = useState<
    {
      Id: string;
      FileAttachmentId: number;
      Comment: string;
      Name: string;
    }[]
  >([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setFileStreamUrl(undefined);
    if (fileStreamUrl) {
      URL.revokeObjectURL(fileStreamUrl); // Revoke the object URL to free up memory
    }
  };

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  };

  const handleNext = () => {
    if (files && currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
      fetchFileStream(files[currentFileIndex + 1].fileAttachmentId);
    }
  };

  const handlePrevious = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
      fetchFileStream(files[currentFileIndex - 1].fileAttachmentId);
    }
  };

  const handleAddFileComment = () => {
    if (!fileCommentText.trim()) {
      alert("Please enter a comment");
      return;
    }

    const currentFile = files[currentFileIndex];
    const fileType = job?.fileAttachmentTypes.find(
      (type) => type.fileAttachmentTypesId === currentFile?.fileAttachmentTypeId
    );

    setFileComments([
      ...filecomments,
      {
        Id: generateUniqueId(),
        FileAttachmentId: files[currentFileIndex]?.fileAttachmentId,
        Comment: fileCommentText,
        Name: fileType ? `${fileType.name} ${currentFile.fileName}` : "",
      },
    ]);
    setFileCommentText("");
  };

  const handleSubmit = () => {
    if (!reviewDecision) {
      alert("Please select approve or reject.");
      return;
    }

    if (reviewDecision === "reject" && !commentText.trim()) {
      alert("Comment is required for rejection.");
      return;
    }

    if (window.confirm("Are you sure you want to submit your review?")) {
      const updateData = {
        JobId: jobId,
        BonusStatus: reviewDecision === "reject" ? "Reject" : "Approve",
        UpdatedBy: accounts[0].username,
        Comment: commentText,
        FileAttachmentComments: filecomments,
      };

      axiosInstance
        .put(`/api/cba/bonuses/${jobId}`, updateData)
        .then(() => {
          if (role) {
            loadBonuses(role);
          }
        })
        .catch((error) => {
          console.error("Error submitting review:", error);
        });

      setOpen(false);
      setCommentText("");
      setReviewDecision("");
    }
  };

  const fetchFileStream = (fileAttachmentId: number) => {
    setLoading(true);
    axiosInstance
      .get(`/api/cba/attachmentfile/${fileAttachmentId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setFileStreamUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching file stream:", error);
        setLoading(false);
      });
  };

  const loadJobDetails = (jobId: number, role: string | null) => {
    axiosInstance
      .get(`/api/cba/job-details/${jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        let fileAttachments: fileAttachmentsProps[] = [];
        if (response?.data?.fileAttachmentsByTypes?.length > 0) {
          response.data.fileAttachmentsByTypes.forEach(
            (fat: fileAttachmentsByTypesProps) => {
              if (fat?.fileAttachments?.length > 0) {
                const hasMergedFile = fat.fileAttachments.find(
                  (file) => file.isMergedFile
                );
                if (hasMergedFile) {
                  fileAttachments.push(hasMergedFile);
                } else {
                  fat.fileAttachments.forEach((fa) => {
                    fileAttachments.push(fa);
                  });
                }
              }
            }
          );
        }

        setFiles(fileAttachments);
        if (fileAttachments.length > 0) {
          fetchFileStream(fileAttachments[0].fileAttachmentId);
        }
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  const loadComments = async (jobId: number) => {
    if (jobId) {
      axiosInstance.get(`/api/cba/comments/${jobId}`).then((response) => {
        setHistoryComments(response.data);
      });
    }
  };

  useEffect(() => {
    if (open) {
      loadJobDetails(jobId, role);
      loadComments(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, jobId, role]);

  const isPdfFile = files[currentFileIndex]?.fileName
    .toLowerCase()
    .endsWith(".pdf");

  return (
    <>
      <Tooltip title="Review">
        <IconButton onClick={handleOpen}>
          <RateReviewIcon
            fontSize="small"
            className="w-5 h-5 text-primary-500"
          />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="xl" open={open} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.primary.main,
            color: theme.palette.common.white,
          }}
        >
          {job ? `${job.jobAddress} - ${job.jobNumber}` : "Job Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={0} sx={{ height: "calc(100vh - 115px)" }}>
          <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                backgroundColor: "#F5F5F5",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                disabled={currentFileIndex === 0 || loading}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography
                variant="subtitle1"
                component="div"
                textAlign="center"
              >
                {files[currentFileIndex]
                  ? `${
                      job?.fileAttachmentTypes.find(
                        (type) =>
                          type.fileAttachmentTypesId ===
                          files[currentFileIndex].fileAttachmentTypeId
                      )?.name || ""
                    } - ${files[currentFileIndex].fileName}`
                  : "No File"}
              </Typography>
              <IconButton
                onClick={handleNext}
                disabled={currentFileIndex === files.length - 1 || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Paper
              elevation={0}
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : isPdfFile ? (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <iframe
                    src={fileStreamUrl}
                    width="100%"
                    height="100%"
                    title="pdf preview"
                  />
                </Box>
              ) : (
                <img
                  src={fileStreamUrl}
                  alt={files[currentFileIndex]?.fileName}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh", // Set max height relative to view height
                    objectFit: "contain",
                  }}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={3} display="flex" flexDirection="column">
            <Box
              sx={{
                px: 2,
                pt: 1,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              {files[currentFileIndex] && (
                <Typography variant="body2" textAlign="left" width="98%">
                  File Specific Comments
                </Typography>
              )}
              <TextField
                multiline
                fullWidth
                minRows={3}
                label={
                  files[currentFileIndex]
                    ? job?.fileAttachmentTypes.find(
                        (type) =>
                          type.fileAttachmentTypesId ===
                          files[currentFileIndex]?.fileAttachmentTypeId
                      )?.name
                    : ""
                }
                variant="filled"
                value={fileCommentText}
                onChange={(e) => setFileCommentText(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box paddingBottom={1} px={2}>
              <Button
                fullWidth
                variant="contained"
                disabled={!fileCommentText.trim()}
                onClick={handleAddFileComment}
                sx={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                Add Comment
              </Button>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "flex-start",
                maxHeight: "28rem",
              }}
            >
              <ListItem
                disableRipple
                button
                onClick={() => setViewHistory(!viewHistory)}
                sx={{
                  position: "static",
                }}
              >
                <ListItemText primary="History" />
                {viewHistory ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <List
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  p: 0,
                }}
              >
                <Collapse in={viewHistory} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Stack divider={<Divider />}>
                      {historyComments ? (
                        historyComments.map((comment) => (
                          <Box
                            key={comment?.id}
                            sx={{
                              mt: 1,
                              px: 2,
                              wordWrap: "normal",
                              wordBreak: "break-word",
                            }}
                          >
                            <Typography variant="body2" color="BlackText">
                              {comment?.createdBy}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="GrayText"
                              sx={{
                                fontSize: "06.rem",
                              }}
                            >
                              {comment?.fileAttachmentTypeName ||
                                "General Comment"}{" "}
                            </Typography>
                            <Box my={1}>
                              <Typography
                                variant="caption"
                                color="CaptionText"
                                sx={{
                                  fontSize: "08.rem",
                                  pl: 0.5,
                                }}
                              >
                                {comment?.comment}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography
                                variant="caption"
                                color="GrayText"
                                textAlign="right"
                              >
                                {moment(comment?.createdOn).format("LLL")}
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  No comment history
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      )}
                    </Stack>
                  </List>
                </Collapse>
              </List>
              <ListItem
                disableRipple
                button
                onClick={() => setViewPending(!viewPending)}
                sx={{
                  position: "static",
                }}
              >
                <ListItemText primary="Pending" />
                {viewPending ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <List
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <Collapse in={viewPending} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Stack divider={<Divider />}>
                      {filecomments.length ? (
                        filecomments.map((fileComment) => (
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  setFileComments(
                                    filecomments.filter(
                                      (commentText) =>
                                        commentText.Id !== fileComment.Id
                                    )
                                  )
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              secondary={fileComment.Comment}
                              primary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {fileComment.Name || ""}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  No pending comments
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      )}
                    </Stack>
                  </List>
                </Collapse>
              </List>
            </Box>
            <Divider />
            <Box px={2}>
              <FormControl>
                <FormLabel id="review-options-label">Review Options</FormLabel>
                <RadioGroup
                  aria-labelledby="review-options-label"
                  value={reviewDecision}
                  onChange={(e) => setReviewDecision(e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="approve"
                    control={<Radio size="small" />}
                    label="Approve"
                  />
                  <FormControlLabel
                    value="reject"
                    control={<Radio size="small" />}
                    label="Reject (comment required)"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                px: 2,
                pb: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                multiline
                fullWidth
                minRows={2}
                label="General Comment"
                variant="filled"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                Submit Review
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ReviewerViewModal;
